import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import "../App.css";
import favicon from "../assets/images/favicon.png";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { useState } from "react";
export default function Navbar() {
  const { open } = useWeb3Modal();
  const [isOpen, setIsOpen] = useState(false);
  const { address } = useAccount();
  function openModal() {
    open();
  }

  return (
    <>
      <div className="ud-header absolute top-0 left-0 z-40 flex w-full items-center bg-transparent">
        <div className="container">
          <div className="relative -mx-4 flex items-center justify-between">
            <div
              style={{ margin: "10px" }}
              className="flex w-full items-center justify-between px-4"
            >
              <div>
                <button
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
                >
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                </button>
                <div
                  // id="navbarCollapse"
                  className={`absolute ${
                    isOpen ? "" : "hidden"
                  } right-4 top-full w-full max-w-[250px] rounded-lg bg-white dark:bg-dark-2 py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent dark:lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6`}
                  style={{ margin: "20px" }}
                >
                  <ul className="block lg:flex 2xl:ml-20">
                    <li className="group relative">
                      <Link
                        to="/"
                        className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark dark:text-white group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70"
                      >
                        {/* <img
                          src={favicon}
                          alt="logo"
                          style={{ height: "48px", width: "48px" }}
                        /> */}
                        Home
                      </Link>
                    </li>
                    <li className="group relative align-middle">
                      <Link
                        to="/idm"
                        className="ud-menu-scroll mx-8 flex align-middle py-2 font-medium text-base text-dark dark:text-white group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70"
                      >
                        IDM
                      </Link>
                    </li>
                    {/* <li className="group relative align-middle">
                      <Link
                        to="/alt"
                        className="ud-menu-scroll mx-8 flex align-middle py-2 font-medium text-base text-dark dark:text-white group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70"
                      >
                        ALT
                      </Link>
                    </li> */}
                    <li className="group relative align-middle">
                      <Link
                        to="https://lotuschain.medium.com/"
                        target= "_blank" 
                        className="ud-menu-scroll mx-8 flex align-middle py-2 font-medium text-base text-dark dark:text-white group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70"
                      >
                        Blog
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="justify-end pr-16 flex items-center lg:pr-0">
                <label
                  for="themeSwitcher"
                  className="inline-flex cursor-pointer items-center"
                  aria-label="themeSwitcher"
                  name="themeSwitcher"
                >
                  <input
                    type="checkbox"
                    name="themeSwitcher"
                    id="themeSwitcher"
                    className="sr-only"
                  />
                  <span className="text-white hidden dark:block">
                    <svg
                      className="fill-current"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.3125 1.50001C12.675 1.31251 12.0375 1.16251 11.3625 1.05001C10.875 0.975006 10.35 1.23751 10.1625 1.68751C9.93751 2.13751 10.05 2.70001 10.425 3.00001C13.0875 5.47501 14.0625 9.11251 12.975 12.525C11.775 16.3125 8.25001 18.975 4.16251 19.0875C3.63751 19.0875 3.22501 19.425 3.07501 19.9125C2.92501 20.4 3.15001 20.925 3.56251 21.1875C4.50001 21.75 5.43751 22.2 6.37501 22.5C7.46251 22.8375 8.58751 22.9875 9.71251 22.9875C11.625 22.9875 13.5 22.5 15.1875 21.5625C17.85 20.1 19.725 17.7375 20.55 14.8875C22.1625 9.26251 18.975 3.37501 13.3125 1.50001ZM18.9375 14.4C18.2625 16.8375 16.6125 18.825 14.4 20.0625C12.075 21.3375 9.41251 21.6 6.90001 20.85C6.63751 20.775 6.33751 20.6625 6.07501 20.55C10.05 19.7625 13.35 16.9125 14.5875 13.0125C15.675 9.56251 15 5.92501 12.7875 3.07501C17.5875 4.68751 20.2875 9.67501 18.9375 14.4Z" />
                    </svg>
                  </span>
                </label>
                <div className="hidden sm:flex"></div>
              </div>
              <div className="justify-end pr-16 flex items-center lg:pr-0">
                <Button color="primary" variant="contained" onClick={openModal}>
                  {!address
                    ? "Connect Wallet"
                    : `${address?.slice(0, 6)}...${address?.slice(-5)}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
