import FooterSection from "../components/footer-section";
import MintSection from "../components/mint-section";
import Navbar from "../components/nav-section";
import PagesHeaderSection from "../components/pages-header-section";

export default function MintPage() {
  return <>
  <Navbar />
  <PagesHeaderSection header="Mint" />
  <MintSection />
  <FooterSection />
  </>
}
