import { ethers } from "ethers";
import { useContext } from "react";
import { altFullDataContext } from "../context";

export function usePurchaseAlt() {
  console.clear();
  const altFullData = useContext(altFullDataContext);
  return async function purchase(altName, walletAddress, price) {
    const address = altFullData.info.address;
    const abi = altFullData.abi;
    const networkId = 80001;
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      networkId
    );
    const signer = provider?.getSigner();
    const contract = new ethers.Contract(address, abi, signer);
    const messageHash = ethers.utils.id(new Date());
    console.log(messageHash);
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [messageHash, walletAddress],
    });
    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);
    const v = parseInt(signature.slice(130, 132), 16);

    console.log(price);
    const pay = ethers.utils.parseEther(price.toString())
    
    return await contract.register(
      walletAddress,
      altName,
      messageHash,
      v,
      r,
      s,
      {
        value:pay,
        gasLimit:300000
      }
    );
  };
}
