import { Button, FormControlLabel, FormGroup, Input, Switch } from "@mui/material";
import { useWeb3Modal } from "@web3modal/react";
import { sendIDM } from "../services/idm";
import { useState } from "react";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";

export default function IdmSection() {
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [foundValue, setFoundValue] = useState(0);
  const [sendFound, setSendFound] = useState(false);
  const {chain} = useNetwork();
  const { address: userWalletAddress } = useAccount();
  const { open } = useWeb3Modal();


  function SendAction() {
    if (!userWalletAddress) return alert("Please connect your wallet");
    if (!ethers.utils.isAddress(address))
      return alert("Wallet Address is not valid");
    if (address === userWalletAddress)
      return alert("You cannot send messages to yourself");
    sendIDM(message, address, foundValue)
      .then(() => {
        alert("Sent");
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to send IDM message");
      });
  }

  function changeFound(e) {
    const value = e.target.checked;
    setSendFound(value)
    if (!value) {
      setFoundValue(0)
    }
  }

  return (
    <section
      id="about"
      style={{overflow: "hidden"}}
      className="dark:bg-dark-2 pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]"
    >
      
          <div style={{ padding: "20px" }} className="-mx-4 flex flex-wrap justify-center items-center">
            <div className="w-full px-4 lg:w-1/3">
                <h1 className="mb-4">Send IDM</h1>
                <div className="mb-4">
                  <Input
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Send to ..."
                  />
                </div>
                <div className="mb-4">
                  <Input
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Your Message"
                  />
                </div>
                <div className="mb-4" style={{display: sendFound? "block" : "none"}}>
                  <Input
                    type="number"
                    inputProps={{step: 0.1}}
                    value={foundValue}
                    onChange={(e) => setFoundValue(e.target.value >= 0 ? e.target.value: 0  )}
                    placeholder="Value"
                  />
                </div>
                <div className="mb-4">
                  <Button
                    onClick={SendAction}
                    variant="contained"
                    color="primary"
                  >
                    Send Message {sendFound? `With ${foundValue} ${chain.nativeCurrency.symbol}` : ""}
                  </Button>
                  <Button onClick={open} style={{marginLeft: "10px"}} variant="contained" color="primary">
                    Switch Network
                  </Button>
                    <FormControlLabel style={{marginLeft: "0.5rem"}} control={<Switch onChange={changeFound} value={sendFound} />} label="Send Found" />
                </div>
            </div>
          </div>
      
    </section>
  );
}
