import { ethers } from "ethers";

/**
 * @description sorry this function is not a react hook but i add it in hooks folder please refactor this function
 * and add to service
 * @param {string} message
 * @param {string} to
 * @returns
 */
export async function sendIDM(message, to, value = "0") {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  var data = ethers?.utils?.solidityPack(["string"], [message]);
  const transaction = {
    to: to,
    data: data,
    gasLimit: 300000,
    value: ethers.utils.parseEther(String(value))
  };

  const signedTransaction = await signer.sendTransaction(transaction);
  return signedTransaction.hash;
}
