import { Button, Input } from "@mui/material";

export default function MintSection() {
  function SendAction() {}

  return (
    <section
      id="about"
      style={{ overflow: "hidden" }}
      className="dark:bg-dark-2 pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]"
    >
      <div
        style={{ padding: "20px" }}
        className="-mx-4 flex flex-wrap justify-center items-center"
      >
        <div className="w-full px-4 lg:w-1/3">
          <h1 className="mb-4">Mint</h1>
          <div className="mb-4">
            <Input fullWidth disabled placeholder="Coming Soon..." />
          </div>
          <div className="mb-4">
            <Button disabled variant="contained" color="primary">
              Comming Soon
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
