import FooterSection from "../components/footer-section";
import IdmSection from "../components/idm-section";
import Navbar from "../components/nav-section";
import PagesHeaderSection from "../components/pages-header-section";

export default function IdmPage() {
  return <>
  <Navbar />
  <PagesHeaderSection header="IDM (Input Data Message)" />
  <IdmSection />
  <FooterSection />
  </>
}
