import { Button, Input } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { switchNetwork } from "wagmi/actions";
import { altFullDataContext } from "../context";
import { useAltPrice } from "../hooks/alt-price.hook";
import { usePurchaseAlt } from "../hooks/alt-purchase";

export default function AltSection() {
  const [altName, setAltName] = useState("");
  const [altPrice, setAltPrice] = useState(0);
  const wallet = useAccount();
  const purchase = usePurchaseAlt();
  const altFullData = useContext(altFullDataContext);
  const { calculateAltPrice } = useAltPrice();
  const { chain } = useNetwork();
  function SendAction() {
    if (chain.id !== altFullData.info.chainID) {
      alert(`Please Switch on ${altFullData.info.network} Network`);
      try {
        switchNetwork({ chainId: altFullData.info.chainID });
        return;
      } catch (error) {}
    }

    purchase(altName, wallet.address, altPrice)
      .then(console.log)
      .catch(console.log);
  }
  useEffect(() => setAltPrice(calculateAltPrice(altName)), [altName]);
  return (
    <section
      id="about"
      style={{ overflow: "hidden" }}
      className="dark:bg-dark-2 pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]"
    >
      <div
        style={{ padding: "20px" }}
        className="-mx-4 flex flex-wrap justify-center items-center"
      >
        <div className="w-full px-4 lg:w-1/3">
          <h1 className="mb-4">ALT</h1>
          <div className="mb-4">
            <Input
              fullWidth
              value={altName}
              onChange={(e) => setAltName(e.target.value.trim())}
              placeholder="ALT Name"
            />
          </div>
          <div className="mb-4">
            <Button onClick={SendAction} variant="contained" color="primary">
              Buy With {parseFloat(altPrice)} {altFullData.info.currency}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
