import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import * as wagmi from "wagmi/chains";
import "./App.css";
import "./assets/css/animate.css";
import "./assets/css/swiper-bundle.min.css";
import "./assets/css/tailwind.css";
import ContextProvider from "./context";
import AltPage from "./pages/alt";
import IdmPage from "./pages/idm";
import MainPage from "./pages/main";
import MintPage from "./pages/min";

const projectId = "a80c1fa61fc8589e2a0fd09ed96acab1";

const chains = [
  wagmi.mainnet,
  wagmi.polygon,
  wagmi.polygonMumbai,
  wagmi.linea,
  wagmi.fantom,
  wagmi.avalanche,
  wagmi.arbitrum,
  wagmi.optimism,
  wagmi.sepolia,
  wagmi.arbitrumGoerli,
  wagmi.aurora,
  wagmi.polygonZkEvm,
  wagmi.harmonyOne,
  wagmi.gnosis,
];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    version: 1,
    chains,
    projectId,
  }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

// createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  return (
    <>
      <ContextProvider>
        <WagmiConfig config={wagmiConfig}>
          <div>
            <Web3Modal
              projectId={projectId}
              ethereumClient={ethereumClient}
            ></Web3Modal>
          </div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/idm" element={<IdmPage />} />
              <Route path="/alt" element={<AltPage />} />
              <Route path="/mint" element={<MintPage />} />
              <Route path="*" element={<MainPage />} />
            </Routes>
          </BrowserRouter>
        </WagmiConfig>
      </ContextProvider>
    </>
  );
}

export default App;
