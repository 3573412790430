import { createContext } from "react";
import {
  defaultValueAltFullData,
  useAltFullData,
} from "../hooks/alt-full-data.hook";

export const altFullDataContext = createContext(defaultValueAltFullData);

export function AltFullDataProvider({ children }) {
  const data = useAltFullData();

  return (
    <altFullDataContext.Provider value={data}>
      {children}
    </altFullDataContext.Provider>
  );
}
