import AltSection from "../components/alt-section";
import FooterSection from "../components/footer-section";
import Navbar from "../components/nav-section";
import PagesHeaderSection from "../components/pages-header-section";

export default function AltPage() {
  return <>
  <Navbar />
  <PagesHeaderSection header="ALT (Alternative Token)beta" />
  <AltSection />
  <FooterSection />
  </>
}
