import { useCallback } from "react";
import { useAltFullData } from "./alt-full-data.hook";

export function useAltPrice() {
  const data = useAltFullData();
  const calculateAltPrice = useCallback(
    (altName = "") => {
      const altNameLength = altName.length;
      if (altNameLength === 0) return 0;
      for (const priceData of data.price) {
        if (priceData.condition === "e" && priceData.value === altNameLength)
          return priceData.price;
        else if (
          priceData.condition === "lte" &&
          priceData.value >= altNameLength
        )
          return priceData.price;
        else if (
          priceData.condition === "gte" &&
          priceData.value <= altNameLength
        )
          return priceData.price;
      }
      return 0;
    },
    [data]
  );

  return {
    calculateAltPrice,
  };
}
