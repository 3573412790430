import { useEffect, useState } from "react";
import { getAltFullInfo } from "../data/alt.data";

export const defaultValueAltFullData = {
  info: {
    address: "",
    network: "",
    chainID: 0,
    currency: "",
  },
  price: [
    {
      condition: "",
      value: 0,
      price: 0,
      sample: "",
      pieces: 0,
      rarity: "",
    },
  ],
  abi: [],
};
export function useAltFullData() {
  const [data, setData] = useState(defaultValueAltFullData);
  useEffect(() => {
    getAltFullInfo()
      .then(setData)
      .catch((err) => console.error(err));
  }, []);
  return data;
}
