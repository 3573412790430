export default function TestimonialSection() {
  return (
    <section
      id="testimonials"
      className="py-20 md:py-[120px] bg-gray-1 dark:bg-dark-2 overflow-hidden"
    >
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-center -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[485px] text-center">
              <span className="block mb-2 text-lg font-semibold text-primary">
                MaDoTa
              </span>
              <h2 className="text-dark dark:text-white mb-3 text-3xl leading-[1.2] font-bold sm:text-4xl md:text-[40px]">
                Smartcontract Audit
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                As an investor, as a developer or as a company, you need to
                prove the safety of the smart contracts you have worked on.
                Financial security and validation in smart contracts means
                securing your future in the blockchain world.
              </p>
            </div>
            <div className="mx-auto max-w-[485px] text-center">
              <a
                href="javascript:void(0)"
                className="inline-block py-3 text-base font-medium text-white transition border border-transparent rounded-md bg-dark px-7 hover:bg-[#3FaFFF]"
              >
                Start audit...
              </a>
              <a
                href="https://www.canva.com/design/DAFkyB-WaWs/qUEvpsWfEpqweATvxn0S2w/view"
                className="inline-block py-3 text-base font-medium text-dark transition border border-transparent rounded-md bg-white px-7 hover:bg-[#3FaFFF]"
              >
                Pitchdeck
              </a>
              <a
                href="https://www.canva.com/design/DAFkyvm384w/0i8M9XaJh2rfX0n6-orKHA/view?website#4"
                className="inline-block py-3 text-base font-medium text-dark transition border border-transparent rounded-md bg-white px-7 hover:bg-[#3FaFFF]"
              >
                Roadmap
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
