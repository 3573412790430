import AboutSection from "../components/about-section";
import ContactSection from "../components/contact-start";
import CTASection from "../components/cta-section";
import FeatureSection from "../components/feature-section";
import FooterSection from "../components/footer-section";
import HeaderSection from "../components/header-section";
import Navbar from "../components/nav-section";
import PricingSection from "../components/pricing-section";
import TestimonialSection from "../components/testimonial-section";

export default function MainPage() {
  return (
    <>
      <Navbar />
      <HeaderSection />
      <FeatureSection />
      {/* <AboutSection /> */}
      <CTASection />
      <TestimonialSection />
      {/* <PricingSection /> */}
      <ContactSection />
      <FooterSection />
    </>
  );
}
